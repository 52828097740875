import React from 'react';


const Footer = () => {

  return (
    <>
      <div style={{ height: '14vh' }}></div>
      <footer style={{ textAlign: 'center', padding: '1rem' }}>
        Aaron Clark -- 2020
    </footer>
    </>
  )
}


export default Footer;
